<template>
  <div
    v-if="todayHours"
    class="w-100 d-flex align-center justify-space-between px-2 py-2 grey lighten2 rounded-md"
  >
    <div class="d-flex align-center">
      <span
        class="store-status"
        :class="{ open: storeStatus, close: !storeStatus }"
      ></span>

      <span class="default--text font-weight-bold">{{
        $t("warehouse.today")
      }}</span>
    </div>
    <span
      class="default--text font-weight-bold"
      v-if="todayHours.hours"
      v-html="todayHours.hours"
    ></span>
  </div>
</template>
<style lang="scss">
.store-status {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 50%;
  &.open {
    background-color: var(--v-green-base);
  }
  &.close {
    background-color: var(--v-primary-base);
  }
}
.warehouse-hours {
  .v-list-item {
    min-height: 30px;
    padding: 5px 0;
    &:not(:last-child) {
      border-bottom: 1px solid var(--v-grey-darken1);
    }
  }
  .v-list-group__header {
    padding: 0;
    display: none;
  }
}
</style>
<script>
import { calculateHourRow } from "@/components/store/warehouseHelper";

export default {
  name: "WarehouseHoursRow",
  props: {
    serviceHours: { type: Object, required: true }
  },
  data() {
    return {
      todayHours: null,
      otherDayHours: [],
      offsetDay: 1
    };
  },
  methods: {},
  computed: {
    storeStatus() {
      return this.todayHours.hours == "Chiuso" ||
        this.todayHours.hours == "chiuso"
        ? false
        : true;
    }
  },
  mounted() {
    // day of week from server: 1 sunday, 7 saturday
    // dayJs: 0 sunday, 6 saturday
    let today = this.$dayjs();
    let todayHours = calculateHourRow(this.serviceHours, today, this.offsetDay);

    this.todayHours = {
      weekDay: today.format("dddd"),
      hours: todayHours ? todayHours : this.$t("warehouses.closed")
    };

    // for (let i = 0; i < 6; i++) {
    //   let dayToCalc = this.$dayjs().add(i + 1, "day");
    //   let otherDay = calculateHourRow(
    //     this.serviceHours,
    //     dayToCalc,
    //     this.offsetDay
    //   );
    //   this.otherDayHours.push({
    //     weekDay: dayToCalc.format("dddd"),
    //     hours: otherDay ? otherDay : this.$t("warehouses.closed")
    //   });
    // }
  }
};
</script>
