<template>
  <div id="infoWindowDialog" style="font-family: Rubik, arial, sans-serif">
    <!-- Warehouse Name -->
    <h3 class="mb-0 d-flex default--text align-center">
      {{ warehouseName }}
    </h3>

    <!-- Warehouse Address -->
    <small class="default--text mb-1">
      {{ address1 }} {{ addressNumber }}, {{ city }} ({{ province }})
      {{ postalcode }}
    </small>

    <!-- Favorite Store Section -->
    <div v-if="isAuthenticated">
      <div
        v-if="isFavoriteStore"
        class="select-warehouse-info-window mb-3 d-flex align-center"
      >
        <v-icon class="mr-2 success--text" size="16">$star</v-icon>
        <small class="success--text font-weight-bold text-uppercase">{{
          $t("warehouse.favouriteStore")
        }}</small>
      </div>
      <button
        v-else
        text
        @click="setPreferredWarehouse(warehouse.address.addressId)"
        class="d-flex align-start mb-3 w-100 text-uppercase primary--text"
      >
        <v-icon class="mr-3 primary--text" size="16">$star</v-icon>
        {{ $t("warehouse.setAsFavouriteStore") }}
      </button>
    </div>

    <!-- Service Hours Section -->
    <v-divider class="my-2"></v-divider>
    <div class="mb-3 pa-3 grey rounded-md d-flex align-center">
      <div class="w-100 d-flex align-center justify-space-between">
        <!--:class="isStoreOpen ? 'open' : 'close'-->

        <WarehouseHoursRow
          :serviceHours="warehouse.serviceHours"
        ></WarehouseHoursRow>
        <!-- <span class="ml-auto default--text font-weight-bold">{{
          formattedServiceHours
        }}</span> -->
      </div>
    </div>

    <!-- Info Window Buttons -->
    <v-btn
      color="primary"
      class="rounded-pill d-flex"
      :to="
        `/volantini?leaflet_types=${this.warehouse.metaData?.warehouse_detail?.LEAFLET_TYPE}`
      "
    >
      {{ $t("warehouse.goToFlyer") }}
    </v-btn>
    <router-link
      class="text-uppercase text-center my-4"
      :to="`/negozi-e-volantini/${this.warehouse.slug}`"
    >
      {{ $t("warehouse.gotToWarehouseDetail") }}
    </router-link>
  </div>
</template>

<script>
import userService from "@/commons/service/userService";
import { mapActions, mapGetters, mapState } from "vuex";
import WarehouseHoursRow from "./WarehouseHoursRow.vue";
export default {
  name: "WarehouseInfoWindow",
  components: { WarehouseHoursRow },
  props: {
    warehouse: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      userData: null,
      warehouseName: this.warehouse.address.addressName || this.warehouse.name,
      address1: this.warehouse.address.address1,
      addressNumber: this.warehouse.address.addressNumber,
      city: this.warehouse.address.city,
      province: this.warehouse.address.province,
      postalcode: this.warehouse.address.postalcode
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    // isStoreOpen() {
    //   const serviceHours = this.warehouse.serviceHours;
    //   const beginTime =
    //     serviceHours.default.length > 0
    //       ? serviceHours.default[0].beginHour
    //       : serviceHours.custom.length > 0
    //       ? serviceHours.custom[0].beginHour
    //       : null;
    //   const endTime =
    //     serviceHours.default.length > 0
    //       ? serviceHours.default[0].endHour
    //       : serviceHours.custom.length > 0
    //       ? serviceHours.custom[0].endHour
    //       : null;

    //   if (!beginTime || !endTime) return false;

    //   const now = new Date();
    //   const currentHour = now.getHours();
    //   const currentMinutes = now.getMinutes();
    //   const currentTime = currentHour * 60 + currentMinutes;
    //   const [beginHour, beginMinute] = beginTime.split(":").map(Number);
    //   const [endHour, endMinute] = endTime.split(":").map(Number);
    //   const beginTimeMinutes = beginHour * 60 + beginMinute;
    //   const endTimeMinutes = endHour * 60 + endMinute;

    //   return currentTime >= beginTimeMinutes && currentTime <= endTimeMinutes;
    // },
    isFavoriteStore() {
      return (
        this.warehouse.warehouseId ===
        this.cart?.user?.defaultStoreAddress.addressId
      );
    }
    // formattedServiceHours() {
    //   const serviceHours = this.warehouse.serviceHours;
    //   const beginTime =
    //     serviceHours.default.length > 0
    //       ? serviceHours.default[0].beginHour
    //       : serviceHours.custom.length > 0
    //       ? serviceHours.custom[0].beginHour
    //       : null;
    //   const endTime =
    //     serviceHours.default.length > 0
    //       ? serviceHours.default[0].endHour
    //       : serviceHours.custom.length > 0
    //       ? serviceHours.custom[0].endHour
    //       : null;
    //   if (!beginTime || !endTime) {
    //     return "";
    //   }

    //   return `${beginTime} - ${endTime}`;
    // }
  },
  methods: {
    ...mapActions({
      getCart: "cart/getCart"
    }),
    async setPreferredWarehouse(addressId) {
      this.userData.defaultStoreAddress = { addressId: addressId };
      console.log("userda", addressId, this.userData);
      try {
        const response = await userService.updateUserDetail(this.userData);
        response && (await this.getCart());
      } catch (error) {
        console.error("error", error);
      }
    }
  },
  mounted() {
    if (this.user) {
      this.userData = this.user;
    }
  },
  watch: {
    warehouse: {
      handler(newWarehouse) {
        console.log(newWarehouse);
      },
      immediate: true
    }
  }
};
</script>
