<template>
  <!-- <div class="address-filter-container" v-if="googleEnabled"> -->
  <v-row
    v-if="warehouseFacets"
    class="mb-5 mb-md-0 grey rounded-md pa-5 align-center justify-space-between"
  >
    <v-col cols="12" sm="6" md="4">
      <div class="d-flex w-100 google-autocomplete-input-container rounded-md">
        <vue-google-autocomplete
          id="pac-input"
          classname="google-autocomplete-input address-to-search"
          ref="addresstosearchref"
          :placeholder="$t('warehouse.filter.searchPdv')"
          :types="'geocode'"
          v-on:placechanged="addressToSearchChanged"
          :enable-geolocation="true"
          country="it"
          @keyup.enter.native="searchFirst"
        >
        </vue-google-autocomplete>

        <v-btn
          :aria-label="$t('common.close')"
          class="geolocate-btn"
          color="primary"
          icon
          @click="resetNearWarehouse()"
          v-if="showNearWarehouse"
        >
          <v-icon>$close</v-icon>
        </v-btn>
        <v-btn
          :aria-label="$t('profile.addresses.enablegeolocalize')"
          class="geolocate-btn"
          color="primary"
          icon
          @click="geolocate()"
          v-else
        >
          <v-icon>$location</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="px-md-4 warehouse-filter">
      <v-select
        clearable
        v-if="provinces"
        dense
        background-color="white"
        hide-details
        return-object
        :items="provinces"
        v-model="selectedProvince"
        item-text="name"
        item-value="id"
        :label="$t('warehouse.filter.province')"
        outlined
        @change="handleFilterResults"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="6" md="4" class="warehouse-filter">
      <v-select
        clearable
        v-if="cities"
        dense
        background-color="white"
        hide-details
        return-object
        :items="cities"
        v-model="selectedCity"
        item-text="name"
        item-value="id"
        :label="$t('warehouse.filter.local')"
        outlined
        @change="handleFilterResults"
      ></v-select>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.warehouse-filter {
  .v-input__control .v-input__slot {
    border: 0 !important;
  }
  .v-input__slot {
    height: 44px !important;
  }

  .v-text-field--outlined fieldset {
    color: white !important;
    border-radius: 8px;
  }
}
.google-autocomplete-input-container {
  display: flex;
  border: 1px solid var(--v-grey-base);
  padding: 3px 6px 3px 15px;
  background: #ffffff;
  font-size: 14px;
  .google-autocomplete-input {
    width: inherit;
    &:focus {
      outline: none;
    }
  }
}
</style>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import addressService from "@/commons/service/addressService";
export default {
  name: "AddressFilter",
  components: { VueGoogleAutocomplete },
  props: {
    warehouseFacets: { type: Array, required: false },
    google: { type: Object, required: true },
    selectedDeliveryServiceId: { type: Number, default: 1 }
  },
  data() {
    return {
      selectedCity: null,
      selectedProvince: null,
      distanceRange: 10000,
      addressToSearch: null,
      showNearWarehouse: false,
      cities: null,
      provinces: null
    };
  },
  computed: {},
  methods: {
    resetNearWarehouse() {
      this.showNearWarehouse = false;
      this.addressToSearch = null;
      document.getElementById("pac-input").value = "";
      this.$emit("resetNearWarehouse");
    },
    emitSearchAddress() {
      this.showNearWarehouse = true;
      this.$emit("addressToSearchChanged", this.addressToSearch);
    },
    distanceRangeChanged() {
      if (this.addressToSearch) {
        this.addressToSearch.distance = this.distanceRange;
        this.emitSearchAddress();
      }
    },
    addressToSearchChanged(address) {
      this.addressToSearch = {
        latitude: address.latitude,
        longitude: address.longitude,
        distance: this.distanceRange
      };
      this.emitSearchAddress();
    },
    geolocate() {
      let vm = this;
      if (navigator.geolocation) {
        document.getElementById("pac-input").value = "";
        document.getElementById("pac-input").placeholder =
          "Localizzazione in corso...";
        navigator.geolocation.getCurrentPosition(
          function(position) {
            var latlng = new vm.google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            let addressData = null;
            var geocoder = new vm.google.maps.Geocoder();
            geocoder.geocode({ location: latlng }, function(results, status) {
              if (status == vm.google.maps.GeocoderStatus.OK) {
                addressData = results[0];
                vm.addressToSearch = {
                  latitude: addressData.geometry.location.lat(),
                  longitude: addressData.geometry.location.lng(),
                  distance: vm.distanceRange
                };
                document.getElementById("pac-input").placeholder = vm.$t(
                  "navbar.service." +
                    vm.selectedDeliveryServiceId +
                    ".searchLabel"
                );
                document.getElementById("pac-input").value =
                  addressData.formatted_address;
                vm.emitSearchAddress();
              } else {
                console.log(
                  "Geocode was not successful for the following reason: "
                );
              }
            });
          },
          function(err) {
            console.log(err);
            alert(
              "è necessario abilitare la localizzazione per utilizzare questa funzione"
            );
          },
          { enableHighAccuracy: false, maximumAge: Infinity }
        );
      }
    },
    async handleFilterResults() {
      let searchParams = {};

      if (this.selectedProvince && this.selectedProvince.id) {
        searchParams.province_id = [this.selectedProvince.id];
      }

      if (this.selectedCity && this.selectedCity.id) {
        searchParams.city_id = [this.selectedCity.id];
      }
      let data = await addressService.findWarehouse(searchParams);
      this.warehouseList = data.warehouses;
      this.$emit("filter-warehouses", this.warehouseList);
    }
  },
  mounted() {
    this.cities = this.warehouseFacets[0].values;
    this.provinces = this.warehouseFacets[1].values;

    // console.log("cities", this.warehouseFacets[0].values);
    // console.log("province", this.warehouseFacets[1].values);
  }
};
</script>
