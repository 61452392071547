<template>
  <div>
    <div
      class="w-100"
      v-if="leafletGroups && leafletGroups.length > 0 && !loading"
    >
      <div
        v-for="(leafletGroup, index) in leafletGroups"
        :key="`leafletGroup_${index}`"
      >
        <v-row class="pa-5 pdv-leaflets grey mb-5 rounded-md">
          <v-col cols="12" md="3">
            <h3 class="text-h4 default--text text-uppercase">
              {{
                $t(
                  `leaflet.headers.${leafletGroup.leaflets[0].leafletType.leafletTypeId}`
                )
              }}
            </h3>
            <span class="leaflet-subtitle">
              {{ $t("leaflet.validity.from") }}
              {{
                leafletGroup.leaflets[0].fromDate | dayjs("format", "D  MMMM")
              }}
              {{ $t("leaflet.validity.to") }}
              {{
                leafletGroup.leaflets[0].toDate | dayjs("format", "D  MMMM")
              }}</span
            >
          </v-col>
          <v-col cols="12" md="8">
            <v-row
              no-gutters
              class="pdv-leaflets__wrapper align-end flex-nowrap"
            >
              <v-col
                class="d-flex flex-column mr-3"
                v-for="leaflet in leafletGroup.leaflets"
                :key="leaflet.leafletId"
                cols="auto"
              >
                <div class="content-wrapper">
                  <h4
                    class="text-h5 default--text content-wrapper__title mb-auto font-weight-bold text-left w-100"
                  >
                    {{
                      $t(`leaflet.types.${leaflet.leafletType.leafletTypeId}`)
                    }}
                  </h4>
                  <v-card
                    :aria-label="`${$t('leaflet.title')}${leaflet.name}`"
                    class="pa-2 rounded-md "
                    :title="leaflet.slug"
                    :to="{
                      name: 'Leaflet',
                      params: {
                        leafletId: leaflet.slug
                          ? leaflet.slug
                          : leaflet.leafletId.toString()
                      }
                    }"
                  >
                    <v-img
                      contain
                      class="leaflet-card__image"
                      width="190px"
                      height="240px"
                      :src="
                        `${leaflet.baseLocation}${leaflet.firstPagePreview}`
                      "
                    />
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else-if="loading">
      <v-row>
        <v-col v-for="index in 3" :key="index" cols="12">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <p v-else>{{ $t("warehouse.noFlyerFound") }}</p>
  </div>
</template>
<style lang="scss" scoped>
.pdv-leaflets {
  .content-wrapper {
    max-width: 210px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    &__title {
      min-height: 50px;
    }
  }
  &__wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: no-wrap;
    .v-card {
      border: 1px solid #d4d4d4;
    }
  }
}
</style>
<script>
import leafletService from "~/service/leafletService.js";
export default {
  name: "PdvLeaflets",
  data() {
    return {
      loading: false,
      leaflets: [],
      leafletGroups: null
    };
  },
  props: {
    parentWarehouseId: { type: Number, required: false },
    parentLeafletTypeIds: { type: Array, required: false }
  },
  async mounted() {
    const leafletGrouping = [
      { group: [10], leaflets: [] },
      { group: [4, 7, 3], leaflets: [] },
      { group: [9, 5, 8, 6], leaflets: [] }
    ];

    for (const parentLeafletTypeId of this.parentLeafletTypeIds) {
      let searchParams = {
        parent_warehouse_id: this.parentWarehouseId,
        parent_leaflet_type_id: parentLeafletTypeId
      };
      try {
        this.loading = true;
        const leaflets = await leafletService.search(searchParams);
        if (leaflets && leaflets.length) {
          const group = leafletGrouping.find(g =>
            g.group.includes(parentLeafletTypeId)
          );
          if (group) {
            group.leaflets.push(...leaflets);
          }
          this.loading = false;
        }
        this.loading = false;
      } catch (err) {
        global.EventBus.$emit("error", {
          error: err,
          message: global.EventBus.$t("errors.general")
        });
        return null;
      }
    }
    this.leafletGroups = leafletGrouping.filter(g => g.leaflets.length > 0);
  }
};
</script>
