<template>
  <div fluid class="warehouse-detail-wrapper">
    <Breadcrumb :items="breadCrumbs" />
    <v-row no-gutters class="px-4 px-lg-9">
      <v-col cols="12">
        <div class="warehouse-list">
          <v-row no-gutters>
            <v-col cols="12">
              <CategoryTitle :category="category" />
            </v-col>
            <v-col cols="12">
              <AddressFilter
                :warehouseFacets="warehouseFacets"
                v-if="google && warehouseFacets"
                :google="google"
                @addressToSearchChanged="addressToSearchChanged"
                @resetNearWarehouse="resetNearWarehouse"
                @filter-warehouses="onFilterWarehouses"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              v-if="showListParsed"
              class="my-4 py-md-3"
              order="2"
              order-sm="1"
              cols="12"
              :sm="showListParsed ? '5' : '0'"
              :xl="showListParsed ? '4' : '0'"
            >
              <v-list class="warehouse-list-list pt-0" :height="mapHeight">
                <v-list-item
                  class="warehouse-list-list--item"
                  v-for="(warehouse, index) in warehouseList"
                  :key="`warehouse_${index}`"
                  two-line
                  dense
                  @click="toggleInfoWindow(warehouse)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="default--text text-capitalize text-left font-weight-medium mb-2"
                    >
                      {{ warehouse.address.addressName || warehouse.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="defaul--text warehouse-address"
                    >
                      {{ warehouse.address.address1 }},{{
                        warehouse.address.addressNumber
                      }}
                      {{ warehouse.address.city }} ({{
                        warehouse.address.province
                      }})
                      <!-- <div
                      v-if="
                        warehouse.metaData.warehouse_locator &&
                          warehouse.metaData.warehouse_locator.PHONE
                      "
                    >
                      Telefono: {{ warehouse.metaData.warehouse_locator.PHONE }}
                    </div> -->
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-icon
                    v-if="
                      warehouse.warehouseId ==
                        cart?.user?.defaultStoreAddress.addressId
                    "
                    color="green"
                    class="mr-3"
                    >$star</v-icon
                  >
                  <v-icon class="font-weight-bold" color="primary"
                    >$arrowForward</v-icon
                  >
                </v-list-item>
              </v-list>
            </v-col>
            <v-col
              class="py-0 py-md-3"
              order="1"
              order-sm="2"
              cols="12"
              :sm="showListParsed ? '7' : '0'"
              :xl="showListParsed ? '8' : '0'"
            >
              <GmapMap
                ref="mapRef"
                :center="{
                  lat: addressToSearch
                    ? addressToSearch.latitude
                    : 46.06989613024087,
                  lng: addressToSearch
                    ? addressToSearch.longitude
                    : 11.125384939249303
                }"
                :zoom="zoomLevel"
                :style="{ width: mapWidth, height: mapHeight }"
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUI: false
                }"
              >
                <GmapMarker
                  :ref="'warehousMarker_' + warehouse.warehouseId"
                  v-for="(warehouse, i) in warehouseList"
                  :key="`warehouse_${i}`"
                  :position="
                    google &&
                      new google.maps.LatLng(
                        warehouse.address.latitude,
                        warehouse.address.longitude
                      )
                  "
                  :clickable="true"
                  icon="/img_layout/warehouse/pin.svg"
                  @click="toggleInfoWindow(warehouse, i)"
                />
                <gmap-info-window
                  :options="infoOptions"
                  :position="infoWindowPos"
                  :opened="infoWinOpen"
                  @closeclick.stop="infoWinOpen = false"
                >
                  <WarehouseInfoWindow
                    v-if="selectedWarehouse"
                    :key="infoWKey"
                    :warehouse="selectedWarehouse"
                    :user="userData"
                  ></WarehouseInfoWindow>
                </gmap-info-window>
                <gmap-circle
                  v-if="addressToSearch"
                  :center="{
                    lat: addressToSearch.latitude,
                    lng: addressToSearch.longitude
                  }"
                  :radius="addressToSearch.distance"
                  :visible="true"
                  :options="{ fillOpacity: 0.3, strokeWeight: 0.3 }"
                >
                </gmap-circle>
              </GmapMap>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12">
        <h2 class="text-h4 mb-5">{{ $t("warehouse.discoverFlyers") }}</h2>
        <PdvLeaflets :parent-leaflet-type-ids="[10, 4, 7, 3, 9, 5, 8, 6]" />
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.select-warehouse-info-window {
  .v-btn:hover,
  .v-btn:focus {
    background-color: transparent !important;
  }
}

.warehouse-list-list {
  &--item {
    border-top: 1px solid var(--v-grey-lighten3);
    //border-bottom: 1px solid var(--v-grey-lighten3);
  }
  height: 380px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: initial;
  }
  overflow-y: auto;
  .warehouse-address {
    line-height: initial;
    font-weight: 400;
  }
  .v-list-item__title {
    text-transform: uppercase;
    color: var(--v-primary-base);
    font-size: 14px;
    font-weight: bold;
  }
  .v-list-item__subtitle {
    color: $text-color;
  }
  .v-avatar {
    padding: 2px;
    margin: 0;
    img {
      width: 24px;
      height: 34px;
    }
  }
}
.gm-style .gm-style-iw-c {
  height: 300px !important;
  width: 340px !important;
  min-height: 300px !important;
}

.gm-style-iw-chr {
  display: none !important;
}

#infoWindowDialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  cursor: pointer;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: var(--v-primary-base);

  h3 {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--v-primary-base);
  }
  .buongusto-infowindow-btn {
    color: var(--v-primary-base) !important;
  }
}
</style>
<script>
//import LeafletsSlider from "../leaflet/LeafletsSlider.vue";
import { mapActions } from "vuex";
import Breadcrumb from "@/components/navigation/Breadcrumb";

import PdvLeaflets from "../leaflet/PdvLeaflets.vue";
import userService from "@/commons/service/userService";
import AddressFilter from "@/components/address/AddressFilter.vue";
import categoryMixins from "~/mixins/category";
import AddressService from "~/service/addressService";
import CategoryTitle from "./CategoryTitle.vue";
import { gmapApi } from "vue2-google-maps";
import { calculateBreadcrumbs } from "@/components/store/warehouseHelper";
//import forEach from "lodash/forEach";
import { mapGetters, mapState } from "vuex";
import WarehouseInfoWindow from "../store/WarehouseInfoWindow.vue";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "WarehouseMap",
  components: {
    AddressFilter,
    CategoryTitle,
    PdvLeaflets,
    WarehouseInfoWindow,
    Breadcrumb
  },
  mixins: [categoryMixins],
  props: {
    showList: { type: String, default: "false" },
    regionId: { type: String, required: false },
    provinceId: { type: String, required: false },
    parentAddressClassId: { type: String, required: false },
    showLocker: { type: String, default: "false" }
  },
  data() {
    return {
      showListParsed: true,
      userData: null,
      showLockerParsed: true,
      warehouseFacets: null,
      warehouseList: [],
      warehouseListOriginal: [],
      infoOptions: {
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      selectedWarehouse: null,
      selectedRegionId: null,
      addressToSearch: null,
      infoWKey: 1
    };
  },
  computed: {
    google: gmapApi,
    mapWidth() {
      return "auto";
    },
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      user: "cart/getUser",
      isAuthenticated: "cart/isAuthenticated"
    }),
    breadCrumbs() {
      return calculateBreadcrumbs(this.$route.fullPath.substring(1));
    },
    mapHeight() {
      return this.$vuetify.breakpoint.xsOnly ? "300px" : "480px";
    },
    pageContent() {
      return this.page && this.page.content ? this.page.content.rendered : "";
    },
    pageTitle() {
      return this.page && this.page.title ? this.page.title.rendered : "";
    },
    zoomLevel() {
      if (this.addressToSearch) {
        return this.addressToSearch.distance == 10000 ? 12 : 10;
      } else {
        return this.$vuetify.breakpoint.xsOnly ? 8 : 9;
      }
    }
  },
  methods: {
    ...mapActions({
      getCart: "cart/getCart"
    }),
    async resetNearWarehouse() {
      this.addressToSearch = null;
      await this.findWarehouse();
    },
    async addressToSearchChanged(addressToSearch) {
      this.addressToSearch = addressToSearch;
      await this.findWarehouse();
    },
    onFilterWarehouses(warehouseList) {
      this.warehouseList = warehouseList;
      this.warehouseListOriginal = warehouseList;
    },
    async findWarehouse() {
      let searchParams = {
        province_id: this.provinceId,
        parent_address_class_id: this.parentAddressClassId,
        region_id: this.regionId
      };
      if (this.addressToSearch) {
        searchParams["parent_latitude"] = this.addressToSearch.latitude;
        searchParams["parent_longitude"] = this.addressToSearch.longitude;
        searchParams["parent_distance"] = this.addressToSearch.distance;
      }
      let data = await AddressService.findWarehouse(searchParams);
      this.warehouseList = data.warehouses;
      this.warehouseListOriginal = data.warehouses;
      this.warehouseFacets = data.facets.filter(facet =>
        ["city", "province"].includes(facet.name)
      );
    },
    // async findLocker() {
    //   this.warehouseList = [];
    //   let data = await AddressService.getAddressByDeliveryService(4);
    //   forEach(data.addresses, a => {
    //     this.warehouseList.push({ metaData: {}, address: a });
    //   });
    // },
    animateMarker(warehouse, status) {
      if (status) {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = this.google.maps.Animation.BOUNCE;
      } else {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = null;
      }
    },

    toggleInfoWindow(warehouse) {
      this.infoWindowPos = {
        lat: warehouse.address.latitude,
        lng: warehouse.address.longitude
      };

      //check if its the same marker that was selected if yes toggle
      if (
        this.selectedWarehouse &&
        this.selectedWarehouse.warehouseId == warehouse.warehouseId
      ) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.selectedWarehouse = cloneDeep(warehouse);
        this.infoWKey++;
      }
    },
    async getUserData() {
      this.userData = await userService.getUserDetail();
    },

    async setPreferredWarehouse(addressId) {
      this.userData.defaultStoreAddress = { addressId: addressId };
      console.log("userda", addressId, this.userData);
      try {
        const response = await userService.updateUserDetail(this.userData);
        response && (await this.getCart());
      } catch (error) {
        console.error("error", error);
      }
    }
  },
  async created() {
    if (this.isAuthenticated) {
      await this.getUserData();
    }
  },
  async mounted() {
    await this.findWarehouse();
    // if (this.showLockerParsed) {
    //   this.findLocker();
    // } else {
    //   this.findWarehouse();
    // }
  }
};
</script>
