var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warehouse-detail-wrapper",attrs:{"fluid":""}},[_c('Breadcrumb',{attrs:{"items":_vm.breadCrumbs}}),_c('v-row',{staticClass:"px-4 px-lg-9",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"warehouse-list"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('CategoryTitle',{attrs:{"category":_vm.category}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.google && _vm.warehouseFacets)?_c('AddressFilter',{attrs:{"warehouseFacets":_vm.warehouseFacets,"google":_vm.google},on:{"addressToSearchChanged":_vm.addressToSearchChanged,"resetNearWarehouse":_vm.resetNearWarehouse,"filter-warehouses":_vm.onFilterWarehouses}}):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[(_vm.showListParsed)?_c('v-col',{staticClass:"my-4 py-md-3",attrs:{"order":"2","order-sm":"1","cols":"12","sm":_vm.showListParsed ? '5' : '0',"xl":_vm.showListParsed ? '4' : '0'}},[_c('v-list',{staticClass:"warehouse-list-list pt-0",attrs:{"height":_vm.mapHeight}},_vm._l((_vm.warehouseList),function(warehouse,index){return _c('v-list-item',{key:`warehouse_${index}`,staticClass:"warehouse-list-list--item",attrs:{"two-line":"","dense":""},on:{"click":function($event){return _vm.toggleInfoWindow(warehouse)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"default--text text-capitalize text-left font-weight-medium mb-2"},[_vm._v(" "+_vm._s(warehouse.address.addressName || warehouse.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"defaul--text warehouse-address"},[_vm._v(" "+_vm._s(warehouse.address.address1)+","+_vm._s(warehouse.address.addressNumber)+" "+_vm._s(warehouse.address.city)+" ("+_vm._s(warehouse.address.province)+") ")])],1),(
                    warehouse.warehouseId ==
                      _vm.cart?.user?.defaultStoreAddress.addressId
                  )?_c('v-icon',{staticClass:"mr-3",attrs:{"color":"green"}},[_vm._v("$star")]):_vm._e(),_c('v-icon',{staticClass:"font-weight-bold",attrs:{"color":"primary"}},[_vm._v("$arrowForward")])],1)}),1)],1):_vm._e(),_c('v-col',{staticClass:"py-0 py-md-3",attrs:{"order":"1","order-sm":"2","cols":"12","sm":_vm.showListParsed ? '7' : '0',"xl":_vm.showListParsed ? '8' : '0'}},[_c('GmapMap',{ref:"mapRef",style:({ width: _vm.mapWidth, height: _vm.mapHeight }),attrs:{"center":{
                lat: _vm.addressToSearch
                  ? _vm.addressToSearch.latitude
                  : 46.06989613024087,
                lng: _vm.addressToSearch
                  ? _vm.addressToSearch.longitude
                  : 11.125384939249303
              },"zoom":_vm.zoomLevel,"options":{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUI: false
              }}},[_vm._l((_vm.warehouseList),function(warehouse,i){return _c('GmapMarker',{key:`warehouse_${i}`,ref:'warehousMarker_' + warehouse.warehouseId,refInFor:true,attrs:{"position":_vm.google &&
                    new _vm.google.maps.LatLng(
                      warehouse.address.latitude,
                      warehouse.address.longitude
                    ),"clickable":true,"icon":"/img_layout/warehouse/pin.svg"},on:{"click":function($event){return _vm.toggleInfoWindow(warehouse, i)}}})}),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){$event.stopPropagation();_vm.infoWinOpen = false}}},[(_vm.selectedWarehouse)?_c('WarehouseInfoWindow',{key:_vm.infoWKey,attrs:{"warehouse":_vm.selectedWarehouse,"user":_vm.userData}}):_vm._e()],1),(_vm.addressToSearch)?_c('gmap-circle',{attrs:{"center":{
                  lat: _vm.addressToSearch.latitude,
                  lng: _vm.addressToSearch.longitude
                },"radius":_vm.addressToSearch.distance,"visible":true,"options":{ fillOpacity: 0.3, strokeWeight: 0.3 }}}):_vm._e()],2)],1)],1)],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-h4 mb-5"},[_vm._v(_vm._s(_vm.$t("warehouse.discoverFlyers")))]),_c('PdvLeaflets',{attrs:{"parent-leaflet-type-ids":[10, 4, 7, 3, 9, 5, 8, 6]}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }