var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.leafletGroups && _vm.leafletGroups.length > 0 && !_vm.loading)?_c('div',{staticClass:"w-100"},_vm._l((_vm.leafletGroups),function(leafletGroup,index){return _c('div',{key:`leafletGroup_${index}`},[_c('v-row',{staticClass:"pa-5 pdv-leaflets grey mb-5 rounded-md"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('h3',{staticClass:"text-h4 default--text text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t( `leaflet.headers.${leafletGroup.leaflets[0].leafletType.leafletTypeId}` ))+" ")]),_c('span',{staticClass:"leaflet-subtitle"},[_vm._v(" "+_vm._s(_vm.$t("leaflet.validity.from"))+" "+_vm._s(_vm._f("dayjs")(leafletGroup.leaflets[0].fromDate,"format", "D MMMM"))+" "+_vm._s(_vm.$t("leaflet.validity.to"))+" "+_vm._s(_vm._f("dayjs")(leafletGroup.leaflets[0].toDate,"format", "D MMMM")))])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',{staticClass:"pdv-leaflets__wrapper align-end flex-nowrap",attrs:{"no-gutters":""}},_vm._l((leafletGroup.leaflets),function(leaflet){return _c('v-col',{key:leaflet.leafletId,staticClass:"d-flex flex-column mr-3",attrs:{"cols":"auto"}},[_c('div',{staticClass:"content-wrapper"},[_c('h4',{staticClass:"text-h5 default--text content-wrapper__title mb-auto font-weight-bold text-left w-100"},[_vm._v(" "+_vm._s(_vm.$t(`leaflet.types.${leaflet.leafletType.leafletTypeId}`))+" ")]),_c('v-card',{staticClass:"pa-2 rounded-md",attrs:{"aria-label":`${_vm.$t('leaflet.title')}${leaflet.name}`,"title":leaflet.slug,"to":{
                    name: 'Leaflet',
                    params: {
                      leafletId: leaflet.slug
                        ? leaflet.slug
                        : leaflet.leafletId.toString()
                    }
                  }}},[_c('v-img',{staticClass:"leaflet-card__image",attrs:{"contain":"","width":"190px","height":"240px","src":`${leaflet.baseLocation}${leaflet.firstPagePreview}`}})],1)],1)])}),1)],1)],1)],1)}),0):(_vm.loading)?_c('div',[_c('v-row',_vm._l((3),function(index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"card"}})],1)}),1)],1):_c('p',[_vm._v(_vm._s(_vm.$t("warehouse.noFlyerFound")))])])
}
var staticRenderFns = []

export { render, staticRenderFns }